//---------------------------------------------------------------------
// Filters Layout
//---------------------------------------------------------------------

$header-fixed-height: 90;
$footer-fixed-height: 90;

.filters-layout {
    height: 100%;
    position: relative;

    @include bpMedium() {
        display: flex;
        flex-direction: column;
    }
}

.filters-layout__header,
.filters-layout__main,
.filters-layout__footer {
    padding-left: remify(30);
    padding-right: remify(30);

    @include bpMedium() {
        padding-left: remify(60);
        padding-right: remify(60);
    }
}

.filters-layout__header {
    // Pad down rest of header content because top-bar is going to lift out a fixed header
    padding-top: remify($footer-fixed-height);

    @include bpMedium() {
        display: flex; // for ie11, need this for the max height on the chips container
        flex-direction: column;
        flex-shrink: 0;
        min-height: 0px;
        position: relative;
        padding-top: remify(30);
        padding-bottom: remify(20);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

        // TODO: Could we use vr-array here?
        & > * + * {
            margin-top: remify(20);
        }
    }
}

.filters-layout__chips {
    min-height: 0px;
    max-height: remify(200); // leave enough height for chips to show
    overflow: auto;
}

// Top bar handles creating a fixed header for mobile
.filters-layout__top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: static;

    @include bpMaxMedium() {
        box-sizing: border-box;
        height: remify($header-fixed-height);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding: remify(30 30 20);
        z-index: 1;
        background-color: palette(neutral, white);
    }
}

.filters-layout__close-btn {
    @include btnReset();
    display: block;
    width: 44px;
    height: 44px;
    position: relative;
    text-indent: -9999px;

    &::before,
    &::after {
        content: '';
        display: block;
        width: 3px;
        height: 28px;
        position: absolute;
        top: calc(50% - 14px);
        left: calc(50% - 1px);
        background-color: palette(brand, blue-dark);
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }
}

.filters-layout__main {
    padding-top: remify(10);
    padding-bottom: remify($footer-fixed-height + 100);

    @include bpMedium() {
        flex-grow: 1;
        padding-top: remify(30);
        padding-bottom: remify(30);
        overflow: auto;
    }
}

.filters-layout__footer {
    display: flex;
    height: remify($footer-fixed-height);
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: palette(neutral, white);
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);

    @include bpMedium() {
        position: static;
        flex-shrink: 0;
    }
}
