//---------------------------------------------------------------------
// Accordion
//---------------------------------------------------------------------

.accordion > * {
    border-top: $base-border;

    &:last-child {
        border-bottom: $base-border;
    }
}

.accordion__heading {
    margin-bottom: remify(30);
    color: palette(brand, kabul);
    font-size: remify(44);
    font-weight: font(weight, medium);
}

.accordion__item__trigger {
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: remify(24 60 24 0);
    position: relative;
    appearance: none;
    background-color: transparent;
    border: none;
    color: palette(brand, kabul);
    cursor: pointer;
    font-size: remify(18);
    font-weight: font(weight, regular);
    line-height: remify(22);
    text-align: left;

    &:active {
        color: palette(brand, kabul);
    }

    &.focus-visible {
        @include resetFocus;
        text-decoration: underline;
    }

    // chevron
    &::after {
        content: '';
        display: block;
        height: 8px;
        width: 8px;
        position: absolute;
        top: calc(50% - 6px);
        right: 10px;
        border-style: solid;
        border-width: 2px 2px 0 0;
        border-color: palette(brand, kabul);
        transform: rotate(135deg);
        transition: all 0.25s ease-in-out;
    }

    @include bpMedium() {
        padding: remify(30 60 30 0);
        font-size: remify(24);
        line-height: remify(27);

        &::after {
            height: 18px;
            width: 18px;
            top: calc(50% - 16px);
        }
    }
}

.accordion__item__main {
    max-width: 85%; // Design direction: create white space directly under the caret icon
    height: 0;
    overflow: hidden;
    padding: 3px; // allows focus states to be visible since overflow cuts some of them off
    transition: all 0.25s ease-in-out;
}

.accordion__item--open {
    padding-bottom: remify(24);

    .accordion__item__trigger {
        color: palette(brand, kabul);
        font-weight: font(weight, medium);

        &:active {
            color: palette(brand, kabul);
        }

        &::after {
            border-color: palette(brand, kabul);
            border-width: 3px 3px 0 0;
            transform: translateY(4px) rotate(-45deg);
        }

        @include bpMedium() {
            padding-bottom: remify(30);

            &::after {
                transform: translateY(10px) rotate(-45deg);
            }
        }
    }

    .accordion__item__main {
        // using height from css prop set on this element in js component
        // auto for ie11
        height: auto;
        height: var(--collapsible-height, auto);
        margin-top: 4px;

        & > * + * {
            margin-top: remify(20);
        }

        @include bpMedium() {
            margin-top: remify(10);
        }
    }
}
