//---------------------------------------------------------------------
// Vertical Rhythm Array (Vertical List)
//---------------------------------------------------------------------

// Increment to be multiplied for each factor
// Value: number without unit
$vrArray-INCREMENT: $base-spacing-unit !default;

// Increment factors to be iterated through
// Value: number
$vrArray-FACTORS: 10 !default;

.vr-array > * {
    display: block;
}

// Vertical Rhythm Array Extensions
//--------------------------------------------------------------------------

@for $VR from 1 through $vrArray-FACTORS {
    // Example class name: .vr-array--2x
    .vr-array--#{$VR}x > * + * {
        margin-top: remify($vrArray-INCREMENT * $VR);
    }
}

.vr-array--border-top {
    & > * {
        border-top: $base-border;
    }
}

.vr-array--divided {
    & > * + * {
        border-top: $base-border;
    }
}

.vr-array--left-indent-6x {
    padding-left: remify($vrArray-INCREMENT * 6);
}
