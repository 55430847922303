//----------------------------------------------------------------------------
// Generic: HTML5 Elements Reset
//----------------------------------------------------------------------------

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
    display: block;
}

audio,
canvas,
video,
progress,
picture {
    display: inline-block;
}

template {
    display: none;
}
