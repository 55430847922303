//---------------------------------------------------------------------
// Media
//
// Image (or other graphical element) on the left, heading, text,
// and/or body copy on the right.
//---------------------------------------------------------------------

.media {
    display: flex;
}

// Media modifiers
.media--stack {
    display: block;

    .media__img {
        margin-bottom: remify(20);
    }
}

// Gutter modifiers
.media--gutter-small > .media__img {
    margin-right: remify(10);
}

.media--gutter-medium > .media__img {
    margin-right: remify(20);
}

.media--gutter-large > .media__img {
    margin-right: remify(30);
}

.media--align-center {
    align-items: center;
}

.media__img {
    flex-shrink: 0;
}

.media__main {
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
}

// Default values for 'flex' in IE11 are '0 0 auto'
// Use this mixin if IE11 content isn't filling out it's available space
// https://caniuse.com/#feat=flexbox (known issues tab)
.media__main--flex-basis-full {
    flex: 0 1 auto;
}
