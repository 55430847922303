//---------------------------------------------------------------------
// Chip
//---------------------------------------------------------------------

.chip {
    display: inline-block;
    height: remify(34);
    padding: remify(0 20);
    margin-bottom: remify(14);
    margin-right: remify($base-spacing-unit * 2);
    position: relative;
    background-color: palette(neutral, mystic);
    border-radius: remify(30);
    font-size: remify(16);
    font-weight: font(weight, light);
    line-height: remify(34);
}

.chip__btn {
    @include btnReset();
    width: remify(34);
    height: remify(34);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
}

.chip__btn__icon {
    display: block;
    width: remify(20);
    height: remify(20);
    position: absolute;
    top: calc(17px - 10px);
    right: 7px;
    background-color: palette(neutral, white);
    border-radius: 50%;
}

.chip__thumbnail {
    display: flex;
    align-items: center;
    justify-content: center;
    width: remify(32);
    height: remify(32);
    position: absolute;
    top: calc(50% - 16px);
    left: remify(3);
    border-radius: 50%;
    font-size: remify(12);
    font-weight: font(weight, bold);
}

.chip__thumbnail--blue-dark {
    background-color: palette(brand, blue-dark);
    color: palette(neutral, white);
}

// Adds remove x
.chip--input {
    padding: remify(0 42 0 20);

    & .chip__btn__icon {
        &::before,
        &::after {
            content: '';
            display: block;
            width: 2px;
            height: 10px;
            position: absolute;
            top: calc(50% - 5px);
            left: calc(50% - 1px);
            background-color: palette(brand, blue-dark);
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    }
}

.chip--thumbnail {
    padding-left: remify(42);
}

.chip--transparent {
    background-color: transparent;
}

.chip--flush {
    & .chip__thumbnail {
        left: 0;
    }
}
