.cta-banner {
    padding: remify(20 35);
    display: flex;
    align-items: center;
}

.cta-banner__txt {
    flex: 1;
}

.cta-banner__cta {
    margin-left: auto;
}
