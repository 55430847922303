//----------------------------------------------------------------------------
// Settings: Fonts
//----------------------------------------------------------------------------

// USAGE
//
// body {
//     font-family: font(family, base);
//     font-weight: font(weight, bold);
//     font-style: font(style, italic);
// }
//
$FONTS: (
    family: (
        base: #{'Roboto',
        'Helvetica Neue',
        'Arial',
        sans-serif},
        code: #{source-code-pro,
        Menlo,
        Monaco,
        Consolas,
        'Courier New',
        monospace},
    ),
    weight: (
        light: 300,
        regular: 400,
        medium: 500,
        bold: 700,
    ),
    style: (
        normal: normal,
        italic: italic,
    ),
);

@function font($font, $style: 'regular') {
    @return map-get(map-get($FONTS, $font), $style);
}
