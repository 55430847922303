//---------------------------------------------------------------------
// Field
//---------------------------------------------------------------------

// <div className="field">
//     <label className="field__label" for="input">
//         Name or Specialty
//     </label>
//     <input
//         className="field__input"
//         id="input"
//         type="text"
//         placeholder="Start typing"
//         autocomplete="off"
//     />
//     <button
//         className="field__clear-btn"
//         type="button">
//         Clear Input
//     </button>
// </div>

.field {
    width: 100%;
    position: relative;
    // box-sizing: border-box;

    // Handles showing clear button if included in the markup as input sibling
    &:not(:hover)
        .field__input:not(:focus)
        ~ .field__clear-btn:not(:focus):not(:hover):not(:active) {
        @include isVisuallyHidden();
    }
}

.field__label,
.field__input {
    display: block;
    width: 100%;
}

// Label

.field__label {
    box-sizing: border-box;
    padding: remify(8 0 12);
    font-weight: font(weight, light);
    font-size: remify(16);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.field__label--bold {
    font-weight: font(weight, bold);
}

.field__label--color-brand-dark {
    color: palette(brand, kabul);
}

// Input

// Field input wrapper is here for adding various extra elements in relation to the actual input
.field__input-wrapper {
}

.field__input {
    box-sizing: border-box;
    appearance: none;
    border: none;
    border-radius: 0px;
    height: remify(54);
    width: 100%;
    padding: remify(18 16);
    background-color: palette(neutral, white);
    color: palette(neutral, mine-shaft);
    font-size: remify(16);

    &::placeholder {
        color: palette(neutral, dusty-gray);
    }
}

// Useful if you want to manually apply "placeholder like" styles to input
.field__input--placeholder {
    color: palette(neutral, dusty-gray);
}

.field__input--has-clear-btn {
    padding-right: remify(48);
    text-overflow: ellipsis;
}

// Remove arrow from IE
select::-ms-expand {
    display: none;
}

textarea.field__input {
    min-height: remify(200);
}

// Field buttons

.field__clear-btn {
    display: block;
    width: remify(20);
    height: remify(20);
    position: absolute;
    top: 50%;
    right: remify(18);
    border-radius: 50%;
    background-color: palette(neutral, white);
    cursor: pointer;
    text-indent: -9999px;

    &::before,
    &::after {
        content: '';
        display: block;
        width: 2px;
        height: 10px;
        position: absolute;
        top: calc(50% - 5px);
        left: calc(50% - 1px);
        background-color: palette(brand, blue-dark);
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }
}

.field__error-msg {
    margin-top: remify(10);
    color: palette(status, error);
    font-size: remify(12);
    line-height: 1.33;
}

// Field modifiers

.field--select {
    .field__input-wrapper {
        position: relative;

        .field__input {
            padding-right: remify(50);
            padding-top: 0;
            padding-bottom: 0;
            cursor: pointer;
        }

        // chevron
        &::before {
            content: '';
            display: block;
            height: 8px;
            width: 8px;
            position: absolute;
            top: calc(50% - 6px);
            right: remify(24);
            border-style: solid;
            border-width: 2px 2px 0 0;
            border-color: palette(neutral, fedora);
            transform: rotate(135deg);
            transition: all 0.25s ease-in-out;
            pointer-events: none;
        }
    }
}

.field--error {
    .field__label {
        color: palette(status, error);
        font-weight: font(weight, regular);
    }

    .field__input {
        border: solid 1px palette(status, error);
    }

    .field__input-wrapper {
        position: relative;

        &::after {
            display: block;
            position: absolute;
            right: remify(30);
            top: calc(50% - 9px);
            content: '!';
            border-radius: 50%;
            border: 1px solid palette(status, error);
            width: remify(18);
            height: remify(18);
            line-height: remify(18);
            text-align: center;
            color: palette(status, error);
        }
    }
}

.field--floating-label {
    & .field__label {
        position: absolute;
        top: 8px;
        left: 16px;
        right: 0;
        padding: 0;
        font-size: remify(14);
    }

    & .field__input {
        padding-top: remify(34);
    }

    & .field__clear-btn {
        top: calc(50% - 10px);
    }
}

.field--flush {
    & .field__label {
        left: 0;
        padding-left: 0;
        padding-right: 0;
    }

    & .field__input {
        padding-left: 0;
    }

    & .field__input--has-clear-btn {
        padding-right: remify(32);
    }

    & .field__clear-btn {
        right: 0;
    }
}

.field--inverse,
.field--transparent {
    & .field__input {
        background-color: palette(neutral, white);
    }

    & .field__clear-btn {
        background-color: palette(neutral, mystic);
    }
}

.field--dark {
    & .field__input {
        color: palette(brand, blue-dark);
        background-color: palette(neutral, mischka);
    }

    &.field--select {
        .field__input-wrapper {
            // chevron
            &::before {
                border-color: palette(brand, blue-dark);
            }
        }
    }
}

.field--transparent {
    & .field__input {
        background-color: transparent;
    }
}

.field--inline {
    display: flex;
    align-items: center;
    > * {
        display: inline-flex;
        width: auto;
    }

    > * + * {
        margin-left: remify(10);
    }
}

.field--bordered {
    & .field__input {
        border: $base-border;
    }
}

// <button className="field">
//     <span className="field__label">
//         Name or Specialty
//     </span>
//     <span className="field__input">
//         Enter Search Term
//     </span>
// </button>

button.field {
    @include btnReset();
    text-align: left;
    padding: 0;

    // Move focus ring to only the input
    &.focus-visible {
        @include resetFocus();

        & .field__input {
            @include defaultFocus();
        }
    }
}

button.field--floating-label {
    height: remify(54);

    & .field__label {
        width: auto;
        z-index: 1;
    }

    & .field__input {
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        padding: remify(0 16);
        overflow: hidden;
        background-color: transparent;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &.focus-visible {
        @include defaultFocus();

        & .field__input {
            @include resetFocus();
        }
    }
}
