// Increment to be multiplied for each factor
// Value: number with unit
$hrArray-INCREMENT: $base-spacing-unit !default;

// Increment factors to be iterated through
// Value: number
$hrArray-FACTORS: 10 !default;

//---------------------------------------------------------------------
// Horizontal Rhythm Array
//---------------------------------------------------------------------

.hr-array {
    display: block;
    font-size: 0;
}

.hr-array > * {
    display: inline-block;
    font-size: remify($base-font-size);
    vertical-align: middle;
}

// Horizontal Rhythm Array Mixins
//--------------------------------------------------------------------------

@for $HR from 1 through $hrArray-FACTORS {
    // Example class name: .hr-array--2x
    .hr-array--#{$HR}x > * + * {
        margin-left: remify($hrArray-INCREMENT * $HR);
    }
}

// @include bpMedium() {
//     @for $HR from 1 through $hrArray-FACTORS {
//         .hr-array--#{$HR}x\@medium > * + * {
//             margin-left: remify($hrArray-INCREMENT * $HR);
//         }
//     }
// }

@mixin hrArrayWrap($rowGutter, $colGutter) {
    display: flex;
    flex-wrap: wrap;
    margin: -#{$rowGutter}px 0 0 -#{$colGutter}px;

    > * {
        display: flex; // for ie11 so child flex contexts work
        margin-left: 0;
        padding: #{$rowGutter}px 0 0 #{$colGutter}px;
    }
}

.hr-array--wrap {
    @include hrArrayWrap(20, 30);
}

@include bpXLarge() {
    .hr-array--wrap-large\@x-large {
        @include hrArrayWrap(20, 60);
    }
}

.hr-array--align-center > * {
    align-self: center;
}

.hr-array--nowrap {
    white-space: nowrap;
}
