//---------------------------------------------------------------------
// Carousel
//--------------------------------------------------------------------

.carousel {
    width: 100vw;

    @include bpLarge() {
        width: calc(100vw - #{$nav-width-desktop});
    }
}

@include ie11('.carousel') {
    width: 100%;
}

.carousel__slider {
}

@include ie11('.carousel__slider') {
    min-height: remify(400);
}

.carousel__slider-tray-wrapper {
}

.carousel__slider-tray.carousel__slider-tray--horizontal {
    box-sizing: border-box;
    padding: remify(0 30);

    @include bpMedium() {
        padding: remify(0 50);
    }
    @include bpLarge() {
        padding: remify(0 70);
    }
}

.carousel__slide {
    > .carousel__inner-slide {
        display: flex;
        align-items: stretch;
        flex-direction: column;

        > * {
            flex-grow: 1;
            min-height: 1px;
        }
    }

    @include ie11('> .carousel__inner-slide') {
        display: block;
    }

    + .carousel__slide {
        margin-left: remify(30);
    }
}

.carousel__button-group {
    display: none;
    margin-bottom: remify(30);

    @include bpMedium() {
        display: block;
        margin-left: remify(50);
    }

    @include bpLarge() {
        margin-left: remify(70);
    }

    & > * + * {
        margin-left: remify(10);
    }
}

.carousel__back-btn,
.carousel__next-btn {
    display: inline-block;
    position: relative;
    width: remify(50);
    height: remify(50);
    border-radius: 50%;
    background-color: palette(neutral, white);

    svg {
        display: block;
        position: absolute;
        top: calc(50% - 10px);
        left: calc(50% - 10px);
        overflow: visible; // to account for iOS cutting off svg icons
        transition: transform 0.3s;
    }

    &:hover,
    &:focus {
        & svg {
            transform: scale(1.25);
        }
    }
}
