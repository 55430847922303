//---------------------------------------------------------------------
// Site
//---------------------------------------------------------------------

.site {
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: $header-height-mobile 1fr;
    grid-template-rows: $header-height-mobile 1fr;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    height: 100vh;
    width: 100%;

    @include bpLarge() {
        -ms-grid-rows: 1fr;
        grid-template-rows: 1fr;
        -ms-grid-columns: $nav-width-desktop 1fr;
        grid-template-columns: $nav-width-desktop 1fr;
    }
}

.site__header {
    background-color: palette(neutral, white);
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;

    @include bpLarge() {
        -ms-grid-row: 1;
        -ms-grid-row-span: 2;
        -ms-grid-column: 1;
    }
}

.site__body {
    background-color: palette(neutral, white);
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > * {
        flex: 0 0 auto;
    }

    @include bpLarge() {
        -ms-grid-row: 1;
        -ms-grid-row-span: 2;
        -ms-grid-column: 2;
    }
}
