//----------------------------------------------------------------------------
// Tools: Mixins
//----------------------------------------------------------------------------

// Accessibly hide elements
@mixin isVisuallyHidden() {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

@mixin isHidden() {
    display: none !important;
}

@mixin isInvisible() {
    visibility: hidden !important;
}

@mixin btnReset() {
    background-color: transparent;
    border: none;
    text-decoration: none;
    cursor: pointer;
}

@mixin imageFillHeight() {
    height: 100%;
    width: auto;
}

@mixin imageFillWidth() {
    height: auto;
    width: 100%;
}

@mixin wrapper() {
    margin: 0 auto;
    max-width: remify(
        1440
    ); // TODO: this was a semi-arbitrary number, put thought into it
    padding-left: remify($wrapper-padding-x);
    padding-right: remify($wrapper-padding-x);

    @include bpMedium() {
        padding-left: remify($wrapper-padding-x--medium);
        padding-right: remify($wrapper-padding-x--medium);
    }

    @include bpLarge() {
        padding-left: remify($wrapper-padding-x--large);
        padding-right: remify($wrapper-padding-x--large);
    }
}

//----------------------------------------------------------------------
// conditional IE11 styles
//
// Provides a way to have styles applied only to IE11.
// Expects a single, simple selector such as '.componentName'
// Can be nested, but cannon nest within
//
// .componentName_isActive {
//     @mixin ie11 .componentName-child {
//         display: block;
//     }
// }
//----------------------------------------------------------------------
@mixin ie11($selector) {
    @media all and (-ms-high-contrast: none) {
        *::-ms-backdrop,
        #{$selector} {
            @content;
        }
    }
}

// Scale Font Size
// https://css-tricks.com/books/fundamental-css-tactics/scale-typography-screen-size/
//
// @param font size (px) values as integers
@mixin scaleFontSize($min, $max) {
    font-size: calc(
        #{$min}px + (#{$max} - #{$min}) * ((100vw - 320px) / (1440 - 320))
    );
    line-height: calc(1.3em + (1.5 - 1.2) * ((100vw - 320px) / (1440 - 320)));
}

// Breakpoints
//
// For the purposes of consistency and ease of use, please nest breakpoint mixins
// inside of each CSS selector rather than isolating them at the bottom of each file.
@mixin bpSmall() {
    @media only screen and (min-width: $viewport-width-sm) {
        @content;
    }
}

@mixin bpMedium() {
    @media only screen and (min-width: $viewport-width-md) {
        @content;
    }
}

@mixin bpLarge() {
    @media only screen and (min-width: $viewport-width-lg) {
        @content;
    }
}

@mixin bpXLarge() {
    @media only screen and (min-width: $viewport-width-xl) {
        @content;
    }
}

@mixin bpMaxSmall() {
    @media only screen and (max-width: $viewport-width-sm - 1) {
        @content;
    }
}

@mixin bpMaxMedium() {
    @media only screen and (max-width: $viewport-width-md - 1) {
        @content;
    }
}

@mixin bpMaxLarge() {
    @media only screen and (max-width: $viewport-width-lg - 1) {
        @content;
    }
}

@mixin bpMaxXLarge() {
    @media only screen and (max-width: $viewport-width-xl - 1) {
        @content;
    }
}

// NOTE: accommodate very small view ports/extreme zoom
@mixin bpXSmall--height() {
    @media only screen and (max-height: 400px) {
        @content;
    }
}

// Focus defaults
@mixin defaultFocus {
    box-shadow: 0 0 0 1px palette(neutral, white),
        0 0 0 2px palette(brand, primary);
    outline: none;
}

@mixin heavyFocus {
    box-shadow: 0 0 0 2px palette(neutral, white),
        0 0 0 4px palette(brand, primary);
}

// Focus reset
@mixin resetFocus {
    outline: none;
    box-shadow: none;

    &::-moz-focus-inner,
    &::-moz-focus-outer {
        border: 0;
    }
}

/*
  This mixin can be used to set the object-fit:
  @include objectFit(contain);
  or object-fit and object-position:
  @include objectFit(cover, top);
*/
@mixin objectFit($fit: fill, $position: null) {
    -o-object-fit: $fit;
    object-fit: $fit;
    @if $position {
        -o-object-position: $position;
        object-position: $position;
        font-family: 'object-fit: #{$fit}; object-position: #{$position}';
    } @else {
        font-family: 'object-fit: #{$fit}';
    }
}

@mixin sneezeGuard($position: fixed) {
    position: $position;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $z-index-overlay;
    background-color: $overlay-color-light;
    animation: fadeIn 0.3s;
}

@mixin tier() {
    padding: remify(30 0);

    @include bpLarge() {
        padding: remify(70 0);
    }
}

@mixin wizardWidth() {
    // Need 100% width minus the total wrapper padding for smoother animation...
    width: calc(100% - #{remify($wrapper-padding-x * 2)});

    @include bpMedium() {
        width: calc(100% - #{remify($wrapper-padding-x--medium * 2)});
    }

    @include bpLarge() {
        width: calc(100% - #{remify($wrapper-padding-x--large * 2)});
    }
}
