//---------------------------------------------------------------------
// Feature
//---------------------------------------------------------------------

.feature {
    display: flex;
    align-items: center;
    padding: remify(48 0);
    border-top: $base-border;
    border-bottom: $base-border;
}

.feature__img {
    flex-shrink: 0;
    width: remify(100);
    height: remify(100);
    margin-right: remify(10);

    @include bpMedium() {
        width: remify(250);
        height: remify(200);
        margin-right: remify(60);
    }

    > img {
        @include objectFit(cover, center);
        height: 100%;
        width: 100%;
    }
}

.feature__main {
    flex-grow: 1;
}
