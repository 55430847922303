$covid-banner-height: remify(65);
$covid-banner-height--large: remify(85);

.covid-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: $covid-banner-height;
    height: auto;
    overflow: hidden;
    background-color: palette(brand, kabul);
    border-bottom: 1px solid palette(neutral, white);
    color: palette(neutral, white);
    font-size: remify(16);
    flex-wrap: wrap;

    @include bpLarge() {
        flex-wrap: nowrap;
        font-size: remify(20);
        height: $covid-banner-height--large;
        justify-content: flex-start;
    }
}

.covid-banner__img {
    height: $covid-banner-height;
    width: auto;
    flex: 0 0 auto;

    @include bpLarge() {
        height: $covid-banner-height--large;
    }

    & > img {
        height: 100%;
        width: auto;
        max-width: 100%;
    }
}

.covid-banner__main {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-start;
    margin: remify($base-spacing-unit) remify(30);

    @include bpLarge() {
        margin-left: remify(40);
        margin-right: remify(40);
    }
}

.covid-banner__title {
    display: none;
    font-weight: font(weight, light);
    flex: 1 1;

    @include bpLarge() {
        display: block;
        padding-right: remify(20);
    }
}

.covid-banner__cta {
    flex: 0 0 auto;
}
