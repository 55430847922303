//----------------------------------------------------------------------------
// Tools: Functions
//----------------------------------------------------------------------------

// Accepts multiple values for shorthand properties
@function remify($values) {
    $rems: ();

    @each $value in $values {
        @if $value == 0 or $value == auto {
            $rems: append($rems, $value);
        } @else {
            $rems: append($rems, ($value / $base-font-size) + rem);
        }
    }
    @return $rems;
}
