//---------------------------------------------------------------------
// Split
//---------------------------------------------------------------------

.split {
    display: flex;
    justify-content: space-between;
}

@include bpMaxMedium() {
    .split--stack\@max-medium {
        display: block;
    }
}

@include bpMaxLarge() {
    .split--stack\@max-large {
        display: block;
    }

    .split--column-centered\@max-large {
        flex-direction: column;
        align-items: center;
    }
}

@include bpMaxXLarge {
    .split--stack\@max-xlarge {
        display: block;
    }
}

.split--align-center {
    align-items: center;
}

.split--column {
    flex-direction: column;
}

.split--align-start > * {
    align-self: flex-start;
}

.split__item {
}

.split__item--grow {
    flex-grow: 1;
}

.split__item--stretch {
    display: flex;
    align-items: stretch;
    flex-direction: column;

    > * {
        min-height: 1px;
        flex-grow: 1;
    }
}
