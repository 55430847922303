//----------------------------------------------------------------------------
// Utilities: Display
//----------------------------------------------------------------------------

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
