//---------------------------------------------------------------------
// Callout
//---------------------------------------------------------------------

.callout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: remify(16);
    color: palette(brand, primary);
}

.callout__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.callout__title {
    margin-bottom: remify(22);
}

.callout__body {
    margin-bottom: remify(24);
}
