//---------------------------------------------------------------------
// Checkbox
//---------------------------------------------------------------------

.checkbox {
    position: relative;
    display: inline-flex;
    align-items: center;
    margin-right: remify(42);
    cursor: pointer;
}

.checkbox--inverse {
    .checkbox__btn {
        background-color: palette(neutral, white);
    }
}

// Hiding checkbox behind btn
.checkbox__input {
    position: absolute;
    left: 0;
    top: auto;
    width: remify(18);
    height: remify(18);
    overflow: hidden;
    opacity: 0; // extra assurance the checkbox is not shown
}

.checkbox__btn {
    flex-shrink: 0;
    position: relative; // make sure btn is above input in stacking context
    display: block;
    width: remify(18);
    height: remify(18);
    margin-right: remify(12);
    background-color: palette(neutral, gallery);
}

.checkbox__label {
    font-weight: font(weight, light);
}

.checkbox__input:checked ~ .checkbox__btn {
    position: relative;
    background-color: palette(brand, kabul);

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        background-color: palette(neutral, white);
    }

    &::before {
        width: 3px;
        height: 12px;
        top: calc(50% - 6px);
        left: calc(50%);
        transform: rotate(40deg);
    }

    &::after {
        width: 3px;
        height: 5px;
        top: calc(50% - 1px);
        left: calc(50% - 5px);
        transform: rotate(-50deg);
    }
}

.checkbox__input.focus-visible ~ .checkbox__btn {
    @include defaultFocus();
}
