//---------------------------------------------------------------------
// Blog Tier
// Specific tier layout for blog components
//---------------------------------------------------------------------

.blog-tier {
    padding: remify(24 0);

    &:last-child {
        padding: remify(24 0 30);

        @include bpLarge() {
            padding: remify(24 0 70);
        }
    }
}

.blog-tier__inner {
    @include wrapper();
}

.blog-tier--info {
    padding: remify(24 0 0);

    @include bpLarge() {
        padding: remify(70 0 0);
    }
}
