//---------------------------------------------------------------------
// Figure
//---------------------------------------------------------------------

.figure {
}

.figure__img {
    img {
        @include objectFit(cover, center);
        display: block;
        width: 100%;
        height: 100%;
    }
}

.figure__caption {
    padding: remify(30);
    background-color: palette(neutral, athens-gray);
}
