//---------------------------------------------------------------------
// Card
//---------------------------------------------------------------------

.card {
    position: relative;
    background-color: palette(neutral, white);
    border: $base-border;
    transition: all 0.15s ease-in-out;
}

.card--border-dark {
    border-color: #d8d8d8;
}

.card--centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.card__image {
    width: 100%;
    height: remify(170);
    overflow: hidden;

    @include bpMedium() {
        height: remify(190);
    }
}
.card__image--tall {
    height: remify(300);

    @include bpMedium() {
        height: remify(400);
    }
}

.card--blue {
    background-color: palette(brand, blue-mid);
    color: palette(neutral, white);
}

.card--inverse {
    color: palette(neutral, white);
}

.card__image > img {
    @include objectFit(cover, center center);
    width: 100%;
    height: 100%;
}

.card__section {
    padding: remify(24);
}

.card__section__divider {
    margin: remify(15) 0;
    border-top: 1px solid palette(neutral, nobel);
}

.card__topper {
    height: 10px;
    background-color: palette(brand, blue-mid);
}

.card__close {
    position: absolute;
    top: remify(28);
    right: remify(20);
    z-index: 2;
}

.card__close-btn {
    display: inline-block;
    width: remify(36);
    height: remify(36);
    padding: remify(10);

    & > svg {
        display: block;
        width: 100%;
        height: 100%;
        fill: palette(brand, blue-dark);
    }
}

.card__tag {
    width: fit-content;
    position: absolute;
    top: remify(0);
    right: 0;
    padding: remify(6 27);
    background-color: palette(brand, gold);
    border-bottom-left-radius: remify(8);

    @include bpLarge {
        right: remify(24);
        border-bottom-right-radius: remify(8);
    }
}

.card__tag--maroon {
    color: palette(neutral, white);
    background-color: palette(brand, maroon);
}

@include bpLarge() {
    .card--pad-lg\@large {
        & .card__section {
            padding: remify(50 40);
        }

        & .card__tag {
            right: remify(40);
        }
    }
}

.card--stretch {
    display: flex;
    flex-direction: column;

    > .card__section {
        display: flex;
        flex-grow: 1;
        min-height: 1px;
    }

    @include ie11(' > .card__section') {
        display: block;
    }
}

@include ie11('.card--stretch') {
    display: block;
}

.card--shadow {
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.08);
    border: none;
}

.card--no-border {
    border: none;
}

.card--scale-active {
    border-left: 4px solid palette(brand, blue-dark);
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.2);
    transform: scale(1.025);
}

a.card {
    cursor: pointer;

    &:hover,
    &:focus {
        box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.2);
        transform: scale(1.025);
    }
}

.card--scale {
    cursor: pointer;
    &:hover,
    &:focus {
        box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.2);
        transform: scale(1.025);
    }
}

.card.focus-visible,
a.card.focus-visible {
    border: 1px solid palette(brand, blue-dark);
}
