//---------------------------------------------------------------------
// Arrow Link
//---------------------------------------------------------------------

.arrow-link {
    display: inline-block;
    position: relative;
    margin-right: remify(48); // to pad over other inline block elements
    color: palette(brand, kabul);
    font-size: remify(16);
    font-weight: font(weight, medium);
    line-height: 1;
    text-decoration: none;

    @include bpMedium() {
        font-size: remify(20);
    }

    // line
    &::before {
        pointer-events: none;
        content: '';
        display: block;
        height: 2px;
        width: 18px;
        position: absolute;
        top: calc(50% - 1px);
        right: 0;
        background-color: currentColor;
        transform: translateX(30px);
        transition: all 0.25s ease-in-out;
    }

    // arrow
    &::after {
        pointer-events: none;
        content: '';
        display: block;
        height: 6px;
        width: 6px;
        position: absolute;
        top: calc(50% - 4px);
        right: 0;
        border-style: solid;
        border-width: 2px 2px 0 0;
        border-color: currentColor;
        transform: translateX(30px) rotate(45deg);
        transition: all 0.25s ease-in-out;
    }

    &:hover,
    &:focus {
        &::before {
            transform: translateX(36px);
        }

        &::after {
            transform: translateX(36px) rotate(45deg);
        }
    }
}

.arrow-link--left {
    padding-left: remify(28);
    margin-right: 0;

    // line
    &::before {
        top: calc(50% - 1px);
        left: 0;
        transform: translateX(0px);
        transition: all 0.25s ease-in-out;
    }

    // arrow
    &::after {
        top: calc(50% - 4px);
        left: 0;
        border-style: solid;
        border-width: 2px 2px 0 0;
        transform: translateX(0px) rotate(225deg);
        transition: all 0.25s ease-in-out;
    }

    &:hover,
    &:focus {
        &::before {
            transform: translateX(-6px);
        }

        &::after {
            transform: translateX(-6px) rotate(225deg);
        }
    }
}

@include ie11('.arrow-link--left:hover::before') {
    transform: none;
    transition: none;
}
@include ie11('.arrow-link--left:focus::before') {
    transform: none;
    transition: none;
}
@include ie11('.arrow-link--left:hover::after') {
    transform: translateX(0px) rotate(225deg);
    transition: none;
}
@include ie11('.arrow-link--left:focus::after') {
    transform: translateX(0px) rotate(225deg);
    transition: none;
}

.arrow-link--16 {
    font-size: remify(16);
}

.arrow-link--inverse {
    color: palette(neutral, white);
}
