//---------------------------------------------------------------------
// Tier
//---------------------------------------------------------------------

.tier {
    padding: remify(30 0);

    @include bpLarge() {
        padding: remify(70 0);
    }
}

.tier__inner {
    @include wrapper();
}

.tier__inner--constrain {
    @include bpLarge() {
        max-width: remify(800);
    }
}

.tier--banner {
    padding: remify(30 0);
}

.tier--thin {
    padding: remify(15 0);
}

.tier--gray {
    background-color: palette(neutral, athens-gray);
}

.tier--blue {
    background-color: palette(brand, blue-mid);
}

.tier--maroon {
    background-color: palette(brand, maroon);
}

.tier--kabul {
    background-color: palette(brand, kabul);
}

.tier--gallery {
    background-color: palette(neutral, gallery);
}
