.site-spinner {
    @include sneezeGuard();
    z-index: 9999;

    .spinner {
        position: relative;
        top: calc(50% - 23px);
        margin-top: 0;
        animation: fadeIn 0.3s;
    }
}
