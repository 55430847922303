//---------------------------------------------------------------------
// Constrain
//---------------------------------------------------------------------

.constrain--660 {
    max-width: remify(660);
}

.constrain--720 {
    max-width: remify(720);
}

.constrain--960 {
    max-width: remify(960);
}
