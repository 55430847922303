//----------------------------------------------------------------------------
// Settings: Global
//----------------------------------------------------------------------------

// Breakpoints
// Includes pixel values
$viewport-width-sm: 500px;
$viewport-width-md: 768px;
$viewport-width-lg: 1025px;
$viewport-width-xl: 1440px;

// Breakpoint Strings
$viewport-width-sm-string: 'small';
$viewport-width-md-string: 'medium';
$viewport-width-lg-string: 'large';
$viewport-width-xl-string: 'xlarge';

// Decoration
$base-border: 1px solid palette(neutral, mystic);
$base-focus-outline: 1px solid palette(brand, blue-light);
$overlay-color-light: rgba(255, 255, 255, 0.7);
$overlay-color-dark: rgba(0, 0, 0, 0.4);

// Landmarks
//  -header & navigation
$header-height-mobile: 58px;
$header-item-margin: 24px;
$nav-item-width: 295px;
$nav-item-margin: 40px;
$nav-logo-height-desktop: 119px;
$nav-logo-width-desktop: 126px;
$nav-logo-height-mobile: 58px;
$nav-logo-width-mobile: 130px;
$nav-logo-height-modal: 104px;
$nav-logo-width-modal: 110px;
$nav-width-desktop: 307px;
$jump-nav-width: 300px;

// Spacing and Alignment
$desktop-gutter: 70px;
$mobile-gutter: 20px;
$base-spacing-unit: 5;

// Spacing for the wrapper() mixin
$wrapper-padding-x: 30;
$wrapper-padding-x--medium: 50;
$wrapper-padding-x--large: 70;

// Text
$base-font-size: 16;
$base-line-height: 26; // prevents ascenders and descenders from being cut off

// Z-Index
$z-index-modal: 99999999; // ridiculous z index for modals because of chatbot widget being 9999999
$z-index-dropdown: 8999;
$z-index-nav: 1999;
$z-index-text-over-image: 10;
$z-index-jump-nav: 3;
$z-index-overlay: 1;

// TODO: remove 'px' from variable except viewport and add 'remify where neccessary'
