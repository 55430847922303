//---------------------------------------------------------------------
// Expandable Text / "Read More"
//---------------------------------------------------------------------

.expandable-text__content {
    display: block;
    overflow: hidden;
    position: relative;
}

.expandable-text__content_isOpen {
    height: auto;
}

.expandable-text__content_isCollapsed::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0) 0px,
        rgba(255, 255, 255, 100)
    );
}

.expandable-text__skip-link {
    @include isVisuallyHidden();
    @extend %txt--link;

    &:focus {
        position: relative;
        height: auto;
        width: auto;
    }
}
