//----------------------------------------------------------------------------
// Settings: Colors
//----------------------------------------------------------------------------

// USAGE
//
// a {
//     color: palette(brand, primary);
// }
//
// TODO: Remove MHF colors
// TODO: Deprecate contextual color names in favor of abstracted ones
// This will allow us to share code across multiple apps more easily
// i.e. replace instances of blue-dark with primary, etc.
$PALETTES: (
    brand: (
        blue-dark: #0c1e44,
        blue-mid: #2e426b,
        blue-light: #294e98,
        maroon: #6a0910,
        gold: #ffb700,
        gold-dark: #f3ab11,
        kabul: #685040,
        cumin: #924a21,
        cumin-dark: #963a05,
        primary: #685040,
    ),
    // named by: http://chir.ag/projects/name-that-color
        neutral:
        (
            mine-shaft: #333333,
            fedora: #777677,
            dusty-gray: #949494,
            nobel: #b4b3b3,
            mischka: #d6dae1,
            mystic: #e5e9ef,
            athens-gray: #f1f3f5,
            white: #ffffff,
            gallery: #ebeae9,
            westar: #e0dfdb,
        ),
    status: (
        error: #ba002b,
    ),
);

@function palette($palette, $tone) {
    @return map-get(map-get($PALETTES, $palette), $tone);
}
