//---------------------------------------------------------------------
// Form Renderer
//---------------------------------------------------------------------

.form-renderer {
    background-color: palette(neutral, gallery);
    padding: remify(50);
    h1 {
        @extend .hdg;
        @include scaleFontSize(24, 36);
    }

    .form-renderer__field-wrapper + .form-renderer__field-wrapper {
        margin-top: remify(40);
    }

    .form-renderer__errors {
        padding: remify(20);
        padding-bottom: remify(13);
        border: solid 1px palette(status, error);
        color: palette(status, error);
        font-size: remify(18);
        font-weight: font(weight, regular);

        .form-renderer__errors__error {
            margin-bottom: remify(7);
        }
    }

    .form-renderer__field-wrapper--error {
    }
}
