//----------------------------------------------------------------------------
// Generic: Form Elements Reset
//----------------------------------------------------------------------------

input[type='search'] {
    box-sizing: content-box;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    appearance: none;
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}

::-moz-focus-inner {
    border: 0;
    padding: 0;
}
