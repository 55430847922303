//---------------------------------------------------------------------
// Blocks
//---------------------------------------------------------------------

.blocks {
    display: block;

    @include bpLarge() {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }
}

.blocks__item {
    display: flex;
    flex-grow: 1;
    box-sizing: border-box;
    padding: remify(30 30 30 0);
}

.blocks--2up .blocks__item {
    @include bpLarge() {
        width: 50%;
    }
}

.blocks--3up .blocks__item {
    @include bpLarge() {
        width: 33.33%;
    }
}

.blocks--4up .blocks__item {
    @include bpSmall() {
        width: 50%;
    }

    @include bpXLarge() {
        width: 25%;
    }
}
