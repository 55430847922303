//---------------------------------------------------------------------
// Grid
//---------------------------------------------------------------------

.grid {
    display: flex;
    flex-wrap: wrap;
}

.grid__col {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
}

.grid--gutter {
    margin: remify(-20) 0 0 remify(-20);

    & > .grid__col {
        padding: remify(20) 0 0 remify(20);
    }
}

.grid--stretch {
    align-items: stretch;

    > .grid__col {
        display: flex;
        flex-direction: column;
        > * {
            flex-grow: 1;
            min-height: 1px;
        }
    }
}

// TODO: Mixin to generate col size classes?
.grid--fit > .grid__col {
    flex: 1 1 0px;
    min-width: 0px;
}

@include bpMedium() {
    .grid--fit\@medium > .grid__col {
        flex: 1 1 0px;
        min-width: 0px;
    }
}

@include bpLarge() {
    .grid--fit\@large > .grid__col {
        flex: 1 1 0px;
        min-width: 0px;
    }

    .grid--2up\@large > .grid__col {
        width: 50%;
    }
}

@include bpXLarge() {
    .grid--3up\@x-large > .grid__col {
        width: 33.3333%;
    }
}
