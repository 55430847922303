//---------------------------------------------------------------------
// Footer
//---------------------------------------------------------------------

$TRANSITION_DURATION: 0.15;

.footer {
    background-color: palette(neutral, gallery);
    color: palette(brand, kabul);
    font-family: font(family, base);
}

.footer__inner {
    @include wrapper();
    padding-top: remify(40);
    padding-bottom: remify(40);

    @include bpLarge() {
        padding-top: remify(50);
        padding-bottom: remify(50);
    }
}

.footer__inner__section + .footer__inner__section {
    margin-top: remify(20);
    padding-top: remify(20);
    border-top: $base-border;
}

.footer__inner__section--main {
    & > * + * {
        margin-top: remify(12);
    }

    @include bpLarge() {
        display: flex;
        flex-wrap: wrap;

        & > * {
            width: 33.3333333%;
        }

        & > * + * {
            margin-top: 0;
        }
    }

    @include bpXLarge() {
        & > * {
            width: 20%;
        }
    }

    & > .footer__social-nav-list {
        margin-top: remify(20);

        @include bpXLarge() {
            width: 20%;
            margin-top: 0;
        }
    }
}

.footer__expandable {
    border-top: $base-border;
    border-color: palette(neutral, nobel);

    @include bpLarge() {
        padding-bottom: 0;
        border: none;
    }

    & + .footer__expandable {
        margin-top: 0;
        border-bottom: $base-border;
        border-color: palette(neutral, nobel);

        @include bpLarge() {
            border: none;
        }
    }
}

.footer__expandable__trigger,
.footer__expandable__header {
    font-weight: font(weight, bold);
    line-height: remify(27);
}

.footer__expandable__trigger {
    cursor: pointer;
    display: block;
    padding: remify(12 0);
    position: relative;
    width: 100%;
    appearance: none;
    border: none;
    background-color: transparent;
    color: palette(brand, blue-dark);
    font-size: remify(16);
    text-align: left;

    &.focus-visible {
        @include resetFocus;
        text-decoration: underline;
    }

    // chevron
    &::after {
        content: '';
        display: block;
        height: 8px;
        width: 8px;
        position: absolute;
        top: calc(50% - 2px);
        right: 24px;
        border-style: solid;
        border-width: 2px 2px 0 0;
        border-color: palette(brand, blue-dark);
        transform: translateY(-2px) rotate(135deg);
        transition: all 0.25s ease-in-out;
    }

    @include bpLarge() {
        display: none;

        &::after {
            display: none;
        }
    }
}

.footer__expandable__header {
    display: none;

    @include bpLarge() {
        display: block;

        & + * {
            margin-top: remify(10);
        }
    }
}

.footer__expandable__content {
    max-width: 85%; // Design direction: create white space directly under the caret icon
    height: 0;
    overflow: hidden;
    padding-left: 4px; // to allow focus border to show
    margin-left: -4px; // to allow focus border to show
    transition: all 0.25s ease-in-out;

    @include bpLarge() {
        height: auto;
    }
}

.footer__expandable--open {
    & .footer__expandable__content {
        // using height from css prop set on this element in js component
        // auto for ie11
        height: auto;
        height: var(--collapsible-height, auto);
        padding-bottom: remify(10);
    }

    & .footer__expandable__trigger {
        &::after {
            transform: rotate(-45deg);
        }
    }
}

.footer__inner__section--footer {
    & > * + * {
        margin-top: remify(20);
    }
}

.footer__inner__section--footer > .simple-content-block {
    & > * + * {
        margin-top: remify(20);
    }

    p {
        font-size: 14px;
        line-height: 22px;
    }

    a {
        color: palette(brand, blue-light);
        text-decoration: underline;
    }
}

.footer__nav-list__item + .footer__nav-list__item {
    margin-top: remify(10);
}

.footer__nav-list__item {
    color: palette(brand, kabul);
    font-size: remify(14);
    line-height: remify(27);
}

.footer__nav-list__item a {
    color: currentColor;
    text-decoration: none;

    &:hover,
    &:focus {
        color: currentColor;
    }

    &:hover {
        text-decoration: underline;
    }
}

.footer__nav-list--primary {
    font-size: remify(16);
    font-weight: font(weight, bold);
}

.footer__nav-list--languages {
    & > * {
        display: inline-block;
    }

    & > .footer__nav-list__item {
        margin-top: 0;
        margin-right: remify(20);
    }

    & .footer__nav-list__item a {
        color: palette(brand, blue-light);
        font-size: remify(14);
        text-decoration: underline;
    }
}

.footer__social-nav-list__item {
    display: inline-block;
    padding-right: remify(12);
}

.footer__social-nav-list__item a {
    display: block;
    width: remify(38);
    height: remify(38);
    position: relative;
    background-color: palette(neutral, white);
    border-radius: 50%;
    color: palette(brand, kabul); // svg currentColor is set from this
    transition: background-color $TRANSITION_DURATION,
        color $TRANSITION_DURATION;

    svg {
        display: block;
        width: remify(20);
        height: remify(20);
        position: absolute;
        top: calc(50% - 10px);
        left: calc(50% - 10px);
        overflow: visible; // to account for iOS cutting off svg icons
        transition: fill $TRANSITION_DURATION, color $TRANSITION_DURATION;
    }

    &:hover,
    &:focus {
        background-color: palette(brand, kabul);
        color: palette(neutral, white); // svg currentColor is set from this
    }
}
